var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-auto mb-3", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "mew-heading-2 py-8 text-center" }, [
          _vm._v(" Here is your new Eth2 Address "),
        ]),
        _c(
          "div",
          {
            staticClass:
              "skip-container d-flex flex-column flex-sm-row rounded align-center justify-space-between greyLight px-5 py-4",
          },
          [
            _c("div", { staticClass: "mb-2 mb-sm-0" }, [
              _vm._v("Already have Eth2 address?"),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center greenPrimary--text cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.onContinue(true)
                  },
                },
              },
              [
                _vm._v(" Skip this step "),
                _c("img", {
                  staticClass: "ml-2",
                  attrs: {
                    height: "17",
                    src: require("@/assets/images/icons/button-circle-right-arrow.svg"),
                    alt: "right arrow",
                  },
                }),
              ]
            ),
          ]
        ),
        _c("border-block", { staticClass: "mt-4 pa-3 pa-sm-5" }, [
          _c("div", { staticClass: "overlayBg rounded pa-5" }, [
            _c("div", { staticClass: "mew-heading-3 mb-3" }, [
              _vm._v("Your Eth2 Address"),
            ]),
            _c("div", { staticClass: "break-word mew-address" }, [
              _vm._v(" " + _vm._s(_vm.eth2Address) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("div", { staticClass: "mew-heading-3 mb-5 pl-md-5" }, [
                _vm._v(" 1. Write down your recovery phrase "),
              ]),
              _c(
                "border-block",
                { staticClass: "px-3 px-sm-7 py-4" },
                [
                  _c("mnemonic-phrase-table", {
                    attrs: { data: _vm.mnemonic },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c("div", { staticClass: "mew-heading-3 mb-5 pl-md-5" }, [
                _vm._v(" 2. Download your keystore file "),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-block d-sm-flex align-center justify-space-between greyLight py-5 px-3 rounded",
                },
                [
                  _c("div", { staticClass: "d-flex align-center" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-keystore-file.svg"),
                        alt: "Keystore file",
                        height: "32",
                      },
                    }),
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "div",
                        { staticClass: "mew-heading-4" },
                        [
                          _vm._v(" Keystore file "),
                          _vm.downloadedKeystore
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { size: "16", color: "greenPrimary" },
                                },
                                [_vm._v("mdi-checkbox-marked-circle")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.downloadedKeystore && _vm.keystoreName
                        ? _c("div", { staticClass: "textLight--text" }, [
                            _vm._v(" " + _vm._s(_vm.keystoreName) + " "),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("mew-button", {
                    staticClass: "my-2",
                    attrs: {
                      "btn-size": "small",
                      title: "Download",
                      "btn-style": "outline",
                      loading:
                        _vm.downloadingKeystore && !_vm.downloadedKeystore,
                      "has-full-width": _vm.$vuetify.breakpoint.xs,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onDownload.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.downloadedKeystore
                ? _c("mew-warning-sheet", {
                    staticClass: "mt-4 mb-1",
                    attrs: { description: _vm.keystoreFileWarning },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "mt-10 d-flex flex-column-reverse flex-md-row align-center justify-center",
          },
          [
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: "Back",
                "btn-style": "outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBack.apply(null, arguments)
                },
              },
            }),
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: _vm.buttonText,
                disabled: !_vm.downloadedKeystore,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onContinue(false)
                },
              },
            }),
          ],
          1
        ),
        _c("staked-create-password-dialog", {
          attrs: { opened: _vm.onCreatePassword },
          on: {
            generate: _vm.generateKeystore,
            onDialogStateChange: _vm.onDialogStateChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }